import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { getDelta, amountFormatter, numberFormatter } from "../../../../helpers/constants/functions"
import {
  GET_PLAYERS_LIST,
} from "../../../../helpers/constants"
import axiosFetch from "../../../../helpers/axios"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import "./styles.scss"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



function AddResult() {

  const [playerOneScore, setPlayerOneScore] = useState(null)
  const [playerTwoScore, setPlayerTwoScore] = useState(null)
  const [playerOne, setPlayerOne] = useState(null)
  const [playerTwo, setPlayerTwo] = useState(null)
  const [error, setError] = useState("")
  const [playersList, setPlayersList] = useState([])

  const [showMatchResult, setShowMatchResult] = useState({})

  useEffect(() => {
    axiosFetch(GET_PLAYERS_LIST).then(res => {
      setPlayersList(res)
    })
  }, [])


  useEffect(() => {
    const samePlayer = playerOne?.id === playerTwo?.id;
    const scoresMax = playerOneScore === 3 && playerTwoScore === 3;
    const scoresLow = playerOneScore < 3 && playerTwoScore < 3;
    const noData = playerOneScore === null || playerTwoScore === null || !playerOne || !playerTwo;
    const showDelta = noData || scoresLow || samePlayer || scoresMax
    console.log("showDelta", showDelta);
    if (!showDelta) {
      const winner = playerOneScore > playerTwoScore ? playerOne : playerTwo
      const loser = playerOneScore < playerTwoScore ? playerOne : playerTwo
      const delta = getDelta(winner.rating, loser.rating)

      const totalSets = Number(playerOneScore) + Number(playerTwoScore)
      const deltaForOneSet = Math.round(((Number(delta.forWin) / totalSets) * 3) * 100) / 100
      const checkRealDelta = playerOneScore === 3 && playerTwoScore === 0 ? delta : { forWin: delta.forWin, forLose: -1 * deltaForOneSet }

      const matchResult = {
        playerOne: playerOne.id,
        playerTwo: playerTwo.id,
        playerOneName: playerOne.name,
        playerTwoName: playerTwo.name,
        scoreOne: playerOneScore,
        scoreTwo: playerTwoScore,
        currentRatingPlayerOne: playerOne.rating,
        currentRatingPlayerTwo: playerTwo.rating,
        deltaOne: winner.id === playerOne.id ? numberFormatter(checkRealDelta.forWin) : numberFormatter(checkRealDelta.forLose),
        deltaTwo: winner.id === playerTwo.id ? numberFormatter(checkRealDelta.forWin) : numberFormatter(checkRealDelta.forLose),
      }

      setShowMatchResult(matchResult)
    }
  }, [playerOneScore, playerTwoScore, playerOne, playerTwo])

  const samePlayer = playerOne?.id === playerTwo?.id;
  const scoresMax = playerOneScore === 3 && playerTwoScore === 3;
  const scoresLow = playerOneScore < 3 && playerTwoScore < 3;
  const noData = playerOneScore === null || playerTwoScore === null || !playerOne || !playerTwo;
  const disabled = noData || scoresLow || samePlayer || scoresMax;

  return (

    <Box sx={{ flexGrow: 1, maxWidth: 800, margin: "0 auto" }}>

      <Grid container spacing={1} className="delta-container">
        <Grid item xs={4} className="select-player-container">
          <Item>
            <Autocomplete
              disablePortal
              id="combo-box-demo-2"
              options={playersList}
              autoHighlight
              value={playerOne}
              onChange={(event, newValue) => {
                setPlayerOne(newValue);
                setError("")
              }}
              getOptionLabel={(option) => `${option.name} (${amountFormatter(option.rating)})`}
              renderInput={(params) => <TextField {...params} type="text" label="Выберите игрока" />}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.name} ({amountFormatter(option.rating)})
                </Box>
              )}
            />
          </Item>
        </Grid>

        <Grid item xs={1} className="r-buttons-container">
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel value="0" labelPlacement="end" control={<Radio />} onChange={() => setPlayerOneScore(0)} />
            <FormControlLabel value="1" labelPlacement="end" control={<Radio />} onChange={() => setPlayerOneScore(1)} />
            <FormControlLabel value="2" labelPlacement="end" control={<Radio />} onChange={() => setPlayerOneScore(2)} />
            <FormControlLabel value="3" disabled={playerTwoScore === 3} labelPlacement="end" control={<Radio />} onChange={() => setPlayerOneScore(3)} />
          </RadioGroup>
        </Grid>
        <Grid item xs={1} className="r-buttons-container a-center">
          <div className="players-score">
            <div>0</div>
            <div>1</div>
            <div>2</div>
            <div>3</div>
          </div>
        </Grid>
        <Grid item xs={1} className="r-buttons-container a-right">
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel value="0" labelPlacement="start" control={<Radio />} onChange={() => setPlayerTwoScore(0)} />
            <FormControlLabel value="1" labelPlacement="start" control={<Radio />} onChange={() => setPlayerTwoScore(1)} />
            <FormControlLabel value="2" labelPlacement="start" control={<Radio />} onChange={() => setPlayerTwoScore(2)} />
            <FormControlLabel value="3" disabled={playerOneScore === 3} labelPlacement="start" control={<Radio />} onChange={() => setPlayerTwoScore(3)} />
          </RadioGroup>
        </Grid>

        <Grid item xs={4} className="select-player-container">
          <Item>
            <Autocomplete
              disablePortal
              id="add-result"
              options={playersList}
              autoHighlight
              value={playerTwo}
              onChange={(event, newValue) => {
                setPlayerTwo(newValue);
                setError("")
              }}
              getOptionLabel={(option) => `${option.name} (${amountFormatter(option.rating)})`}
              renderInput={(params) => <TextField {...params} type="text" label="Выберите игрока" />}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.name} ({amountFormatter(option.rating)})
                </Box>
              )}
            />
          </Item>
        </Grid>
        {error && <div className="error">{error}</div>}
        {!disabled && <Grid item xs={12} className="quick-result">
          <div className="score-delta left">
            <span className={`${Number(playerOneScore) < Number(playerTwoScore) ? "delta-minus" : "delta-plus"}`}>({Math.round(Number(showMatchResult.deltaOne) * 100) / 100})</span>
            <b>{playerOneScore}</b>
          </div>
          <div className="score-delta">:</div>
          <div className="score-delta right">
            <b>{playerTwoScore}</b>
            <span className={`${Number(playerOneScore) > Number(playerTwoScore) ? "delta-minus" : "delta-plus"}`}>({Math.round(Number(showMatchResult.deltaTwo) * 100) / 100})</span>
          </div>
        </Grid>}
      </Grid>

      {/* {showMatchResult.deltaOne && showMatchResult.deltaTwo && renderEventGames()} */}


    </Box>
  );
}

const mapStateToProps = (state) => ({

});

export default withRouter(
  compose(
    connect(mapStateToProps, {}),
  )(AddResult)
);